import { createSignal } from "solid-js";
import ky, { KyInstance } from "ky";

import { baseOptions } from "#root/module/api";

type HTTPData = {
  client: KyInstance;
  csrfValue?: string;
};

type HTTPMutations = {
  setCsrfToken: (token: string) => void;
  getCsrfToken: () => string | undefined;
  getClient: () => KyInstance;
};

const [httpState, setHttpState] = createSignal<HTTPData>({
  client: ky.create(baseOptions),
});

const actions: HTTPMutations = {
  setCsrfToken(token) {
    setHttpState((currentVal) => {
      return {
        ...currentVal,
        client: currentVal.client.extend({
          headers: {
            "x-csrf-token": token,
          },
        }),
        csrfValue: token,
      };
    });
  },
  getClient() {
    return httpState().client;
  },
  getCsrfToken() {
    return httpState().csrfValue;
  },
};

export const useHttp = () => actions;
